<!-- eslint-disable prettier/prettier -->
<template>
  <div class="create-post" v-if="admin">
    <div class="loading" v-if="loading"></div>
    <BlogCoverPreview v-show="this.$store.state.blogPhotoPreview" />
    <div class="container">
      <div :class="{invisible: !error}" class="err-message">
        <p><span>Error:</span>{{ this.errorMsg }}</p>
      </div>
      <div class="publish">
        <button @click="updateBlog(true, false)" v-if="!blogPublished"><b-icon-journal-arrow-up class="publish-icon"></b-icon-journal-arrow-up>Publicar</button>
        <button @click="updateBlog(false, false)" v-else><b-icon-journal-arrow-down class="publish-icon"></b-icon-journal-arrow-down>Ocultar</button>
      </div>
      <div class="blog-info">
        <input type="text" placeholder="Enter Blog Title" v-model="blogTitle">
        <div class="upload-file">
          <label for="blog-photo">Subir Foto de Portada</label>
          <input type="file" ref="blogPhoto" @change="fileChange" id="blog-photo" accept=".png, .jpg, .jpeg" />
          <button @click="openPreview" class="preview" :class="{'button-inactive' : !this.$store.state.blogPhotoFileURL}">Previsualizar Imagen</button>
          <span>Archivo seleccionado: {{ this.$store.state.blogPhotoName }}</span>
        </div>
      </div>
      <!--
      <div>
        <TagInput :savedTags=blogTags @inputChanged="getTags"></TagInput>
      </div>
      -->
      <div class="optional-inputs">
        <div class="tag-input">
          <div v-for="(tag, index) in tags" :key="index" class="tag">
            <span @click="removeTag(index)">x</span>
            {{ tag }}
          </div>
          <input
            @keydown="addTag"
            @keydown.delete="removeLastTag"
            type="text"
            placeholder="Agrega una etiqueta"
            class="tag-text"
          />
        </div>
        <input placeholder="Autor" type="text" class="author-input" v-model="blogAuthor" />
        <select class="form-control" v-model="blogCategory">
          <option value="" disabled selected hidden>Selecciona una categoría</option>
          <option v-for="(category, index) in blogCategories" :key="index">{{category}}</option>
        </select>
      </div>
      <div class="short-description">
        <b-form-textarea
        id="textarea"
        v-model="blogShortDescription"
        placeholder="Ingresa descripción corta (190 caracteres)"
        rows="4"
        max-rows="4"
        maxlength="190"
        no-resize>
        </b-form-textarea>
        <p class="input-counter">{{ blogShortDescription ? 190 - blogShortDescription.length : 190 }} caracteres restantes</p>
      </div>
      
      <div class="editor">
        <vue-editor :editorOptions="editorSettings" v-model="blogHTML" useCustomImageHandler @image-added="imageHandler" />
      </div>
      <div class="blog-actions">
        <button @click="updateBlog(true, true)">Guardar Cambios</button>
        <router-link class="router-button" :to="{name: 'blogPreview'}">Vista Previa</router-link>
      </div>
      
    </div>
  </div>
</template>

<script>
import BlogCoverPreview from '@/components/blog/BlogCoverPreview.vue';
//import TagInput from '@/components/blog/TagInput.vue';
import Quill from 'quill';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import db from '../assets/firebase/firebaseInit';

window.Quill = Quill;
const ImageResize = require('quill-image-resize-module').default;
Quill.register('modules/imageResize', ImageResize);

let Size = Quill.import('attributors/style/size');
Size.whitelist = ['30px'];
Quill.register(Size, true);

export default {
  metaInfo() {
    return {
      title: 'Create Post',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Create Post',
        },
        { property: 'og:site_name', content: 'Vanglar' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  data() {
    return {
      loading: false,
      file: null,
      error: null,
      errorMsg: null,
      routeID: null,
      currentBlog: null,
      editorSettings: {
        modules: {
          imageResize: {},
          /*
          toolbar: [
            [{ size: ['30px'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [
              { align: '' },
              { align: 'center' },
              { align: 'right' },
              { align: 'justify' },
            ],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ color: [] }, { background: [] }],
            ['link', 'image', 'video'],
            ['clean'],
          ],
          */
        },
      },
    };
  },
  async mounted() {
    this.routeID = this.$route.params.blogid;
    this.currentBlog = this.$store.state.blogPosts.filter((post) => {
      return post.blogID === this.routeID;
    });
    this.$store.commit('setBlogState', this.currentBlog[0]);
  },
  methods: {
    addTag(event) {
      if (event.code == 'Comma' || event.code == 'Enter') {
        event.preventDefault();
        let val = event.target.value.trim();
        if (val.length > 0) {
          this.tags.push(val);
          event.target.value = '';
        }
        this.$emit('inputChanged', this.tags);
      }
    },
    removeTag(index) {
      this.tags.splice(index, 1);
      this.$emit('inputChanged', this.tags);
    },
    removeLastTag() {
      if (event.target.value.length === 0) {
        this.removeTag(this.tags.length - 1);
        this.$emit('inputChanged', this.tags);
      }
    },
    getTags(values) {
      this.blogTags = values;
    },
    fileChange() {
      this.file = this.$refs.blogPhoto.files[0];
      const fileName = this.file.name;
      this.$store.commit('fileNameChange', fileName);
      this.$store.commit('createFileURL', URL.createObjectURL(this.file));
    },
    openPreview() {
      this.$store.commit('openPhotoPreview');
    },
    imageHandler(file, Editor, cursorLocation, resetUploader) {
      const storageRef = firebase.storage().ref();
      const docRef = storageRef.child(`documents/blogPostPhotos/${file.name}`);
      docRef.put(file).on(
        'state_changed',
        (snapshot) => {
          console.log(snapshot);
        },
        (err) => {
          console.log(err);
        },
        async () => {
          const downloadURL = await docRef.getDownloadURL();
          Editor.insertEmbed(cursorLocation, 'image', downloadURL);
          resetUploader();
        }
      );
    },
    async updateBlog(publish = true, save = true) {
      this.loading = true;
      const dataBase = await db.collection('blogPosts').doc(this.routeID);
      if (this.blogTitle.length !== 0 && this.blogHTML.length !== 0) {
        if (this.file) {
          const storageRef = firebase.storage().ref();
          const docRef = storageRef.child(
            `documents/BlogCoverPhotos/${this.$store.state.blogPhotoName}`
          );
          docRef.put(this.file).on(
            'state_changed',
            (snapshot) => {
              console.log(snapshot);
            },
            (err) => {
              console.log(err);
            },
            async () => {
              const downloadURL = await docRef.getDownloadURL();
              await dataBase.update({
                blogHTML: this.blogHTML,
                blogCoverPhoto: downloadURL,
                blogCoverPhotoName: this.blogPhotoName,
                blogTitle: this.blogTitle,
                blogTags: this.blogTags,
                blogAuthor: this.blogAuthor,
                blogCategory: this.blogCategory,
                blogShortDescription: this.blogShortDescription,
                blogPublished: save
                  ? this.blogPublished
                  : publish
                  ? true
                  : false,
              });
              console.log(save ? this.blogPublished : publish ? true : false);
              await this.$store.dispatch('updatePost', this.routeID);
              this.$router.push({
                name: 'viewBlog',
                params: { blogid: dataBase.id },
              });
            }
          );
          return;
        }
        await dataBase.update({
          blogHTML: this.blogHTML,
          blogTitle: this.blogTitle,
          blogTags: this.blogTags,
          blogAuthor: this.blogAuthor,
          blogCategory: this.blogCategory,
          blogShortDescription: this.blogShortDescription,
          blogPublished: save ? this.blogPublished : publish ? true : false,
        });
        await this.$store.dispatch('updatePost', this.routeID);
        this.blogPublished = save ? this.blogPublished : publish ? true : false;
        this.loading = false;
        return;
      }
      this.error = true;
      this.errorMsg = 'Asegúrate que agregaste la imagen de portada';
      setTimeout(() => {
        this.error = false;
      }, 5000);
      this.loading = false;
      return;
    },
  },
  computed: {
    admin() {
      return this.$store.state.profileAdmin;
    },
    profileId() {
      return this.$store.state.profileId;
    },
    blogPhotoName() {
      return this.$store.state.blogPhotoName;
    },
    blogTitle: {
      get() {
        return this.$store.state.blogTitle;
      },
      set(payload) {
        this.$store.commit('updateBlogTitle', payload);
      },
    },
    blogCategories() {
      return Object.keys(this.$store.state.blogCategories);
    },
    tags: {
      get() {
        return this.$store.state.blogTags;
      },
      set(payload) {
        this.$store.commit('updateBlogTags', payload);
      },
    },
    blogTags: {
      get() {
        return this.$store.state.blogTags;
      },
      set(payload) {
        this.$store.commit('updateBlogTags', payload);
      },
    },
    blogAuthor: {
      get() {
        return this.$store.state.blogAuthor;
      },
      set(payload) {
        this.$store.commit('updateBlogAuthor', payload);
      },
    },
    blogCategory: {
      get() {
        return this.$store.state.blogCategory;
      },
      set(payload) {
        this.$store.commit('updateBlogCategory', payload);
      },
    },
    blogShortDescription: {
      get() {
        return this.$store.state.blogShortDescription;
      },
      set(payload) {
        this.$store.commit('updateBlogShortDescription', payload);
      },
    },
    blogPublished: {
      get() {
        return this.$store.state.blogPublished;
      },
      set(payload) {
        this.$store.commit('updateBlogPublished', payload);
      },
    },
    blogHTML: {
      get() {
        return this.$store.state.blogHTML;
      },
      set(payload) {
        this.$store.commit('newBlogPost', payload);
      },
    },
  },
  //components: { BlogCoverPreview, TagInput },
  components: { BlogCoverPreview },
};
</script>

<style lang="scss" scoped>
.create-post {
  position: relative;
  height: 100%;
  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000065;
    z-index: 10;
  }
  button {
    margin-top: 0;
  }
  .router-button {
    text-decoration: none;
    color: white;
  }
  label,
  button,
  .router-button {
    transition: 0.5s ease-in-out all;
    align-self: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 20px;
    padding: 12px 24px;
    color: white;
    background-color: #303030;
    text-decoration: none;
    &:hover {
      background-color: rgba(48, 48, 48, 0.7);
    }
  }
  .container {
    position: relative;
    height: 100%;
    padding: 10px 25px 60px;
    .publish {
      margin: 1rem 0;
      display: flex;
      justify-content: flex-end;
      button {
        display: flex;
        align-items: center;
        .publish-icon {
          margin-right: 0.5rem;
          font-size: 1.5rem;
        }
      }
    }
  }

  //error class
  .invisible {
    opacity: 0 !important;
  }
  .err-message {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    color: white;
    margin-bottom: 10px;
    background-color: #303030;
    opacity: 1;
    transition: 0.5s ease all;
    p {
      font-size: 14px;
    }
    span {
      font-weight: 600;
    }
  }
  .optional-inputs {
    display: flex;
    align-items: center;
    gap: 2rem;
    .author-input {
      width: 50%;
      transition: 0.5s ease-in-out all;
      padding: 10px 4px;
      border: none;
      border-bottom: 1px solid #303030;
      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #303030;
      }
    }
  }
  .blog-info {
    display: flex;
    margin-bottom: 32px;
    input:nth-child(1) {
      min-width: 300px;
    }
    input {
      transition: 0.5s ease-in-out all;
      padding: 10px 4px;
      border: none;
      border-bottom: 1px solid #303030;
      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #303030;
      }
    }
    .upload-file {
      flex: 1;
      margin-left: 16px;
      position: relative;
      display: flex;
      align-items: center;
      input {
        display: none;
      }
      .preview {
        margin-left: 16px;
        text-transform: initial;
      }
      span {
        font-size: 12px;
        margin-left: 16px;
      }
      label {
        margin-bottom: 0;
      }
    }
  }
  .short-description {
    margin-bottom: 1rem;
    position: relative;
    .input-counter {
      color: #b5b5b5;
      position: absolute;
      right: 5px;
      bottom: -10px;
    }
  }
  .editor {
    height: 60vh;
    display: flex;
    flex-direction: column;
    .quillWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .ql-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll;
    }
    .ql-editor {
      padding: 20px 16px 30px;
    }
  }
  .blog-actions {
    margin-top: 60px;
    button {
      margin-right: 16px;
    }
  }
}
.button-inactive {
  cursor: auto !important;
  background-color: #858585 !important;
}

.tag-input {
  width: 100%;
  border: 1px solid #eee;
  font-size: 0.9rem;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 1rem 0;
  .tag {
    height: 30px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 10px;
    line-height: 30px;
    padding: 0 5px;
    border-radius: 5px;
    span {
      cursor: pointer;
      opacity: 0.75;
    }
  }
  .tag-text {
    border: none;
    outline: none;
    font-size: 0.9rem;
    line-height: 50px;
    background: none;
  }
}

.ql-snow .ql-picker-options .ql-picker-item {
  content: 'Huge' !important;
}

.ql-snow .ql-picker-options .ql-picker-item[data-value='18px']::before {
  content: 'Huge';
  font-size: 18px !important;
}
</style>
