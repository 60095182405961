<!-- eslint-disable prettier/prettier -->

<template>
    <div>
      <SlideComponent title="Analítica de datos" image="analiticaDatos/analitica01.webp" description="Transforma los datos en información útil para la toma de decisiones." :descriptionLeft=false height="800">
      </SlideComponent>

      <v-container style="height=20px"></v-container>
      <SlideComponent title="Servicios del más alto valor" description="Vanglar ha colaborado en proyectos de investigación con las instituciones académicas más importantes en México: UNAM, CIDE e IPN, desde la perspectiva de análisis de datos." image="analiticaDatos/analitica02.webp" :descriptionLeft=true>
        <template v-slot:extra>
            <p class="text-content">
                Ya sea que se requiera un tablero de control, inteligencia de negocios o consultoría de análisis del más alto nivel, Vanglar se adapta a la cultura analítica de la organización y entrega resultados.
            </p>
        </template>
      </SlideComponent>
      <SlideComponent title="Soluciones Vanglar" description="Sin importar en qué punto esté la organización en su curva analítica, Vanglar es un socio estratégico de negocios para los problemas que se enfrentan." image="analiticaDatos/analitica03.webp" button="CONÓCENOS" :descriptionLeft=false height="800">
      </SlideComponent>
      <div class="title">
       <h1 class="header1">Laboratorio de Crecimiento</h1>
       <h2 class="header2 text-center">(www.laboratoriodecrecimiento.com)</h2>
       <p class="text-content">Especializados en el Mapa de la Complejidad Económica de la Universidad de Harvard y el MIT, Vanglar entrega los servicios de más alto valor para generar oportunidades de negocio a nivel local e impulsar el crecimiento económico.</p>
      </div>
      <v-container class="fluid">
        <v-row class="justify-center">
          <v-col align="center">
            <b-img fluid :src="require('@/assets/analiticaDatos/analitica04.webp')" alt="content"></b-img>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col align="center">
            <router-link :to="{name : 'contacto'}">
              <b-button size="lg" variant="primary">CONTÁCTANOS</b-button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
      <AnaliticaDatosCards></AnaliticaDatosCards>
      <v-col align="center" class="mb-15">
        <router-link :to="{name : 'contacto'}">
          <b-button size="lg" variant="primary">CONTÁCTANOS</b-button>
        </router-link>
      </v-col>
    </div>
  </template>

<script>
import SlideComponent from '@/components/general/SlideComponent.vue';
import AnaliticaDatosCards from '@/components/analiticaDatos/AnaliticaDatosCards.vue';

export default {
  metaInfo() {
    return {
      title: 'Compliance',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Compliance',
        },
        { property: 'og:site_name', content: 'Vanglar' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  components: {
    SlideComponent,
    AnaliticaDatosCards,
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  flex-direction: column;
  margin: 5rem auto 0 auto;
  width: 70%;
}

@media (max-width: 767px) {
  .title {
    width: 90%;
  }
}

@media (max-width: 470px) {
  .title {
    margin-top: 2rem;
  }
}
</style>
