<!-- eslint-disable prettier/prettier -->
<template>
  <div class="create-post" v-if="admin">
    <div class="loading" v-if="loading"></div>
    <BlogCoverPreview v-show="this.$store.state.blogPhotoPreview" />
    <div class="container">
      <div :class="{ invisible: !error }" class="err-message">
        <p><span>Error:</span>{{ this.errorMsg }}</p>
      </div>
      <div class="blog-info">
        <input type="text" placeholder="Ingresa título de blog" v-model="blogTitle">
        <div class="upload-file">
          <label for="blog-photo">Subir Foto de Portada</label>
          <input type="file" ref="blogPhoto" @change="fileChange" id="blog-photo" accept=".png, .jpg, .jpeg" />
          <button @click="openPreview" class="preview"
            :class="{ 'button-inactive': !this.$store.state.blogPhotoFileURL }">Previsualizar Imagen</button>
          <span>Archivo seleccionado: {{ this.$store.state.blogPhotoName }}</span>
        </div>
      </div>
      <div class="optional-inputs">
        <TagInput class="tag-input" @inputChanged="getTags" :savedTags=blogTags></TagInput>
        <input placeholder="Autor" type="text" class="author-input" v-model="blogAuthor" />
        <!-- 
        <select class="form-control" v-model="blogCategory">
          <option value="" disabled hidden>Selecciona una categoría</option>
          <option v-for="(category, index) in blogCategories" :key="index">{{ category }}</option>
        </select>
        -->
        <v-select class="categories" label="Selecciona una categoría" :items="blogCategories" v-model="blogCategory" variant="solo"></v-select>
      </div>
      <div class="short-description">
        <b-form-textarea id="textarea" v-model="blogShortDescription"
          placeholder="Ingresa descripción corta (190 caracteres)" rows="4" max-rows="4" maxlength="190" no-resize>
        </b-form-textarea>
        <p class="input-counter">{{ blogShortDescription ? 190 - blogShortDescription.length : 190 }} caracteres restantes
        </p>
      </div>
      <div class="editor">
        <vue-editor :editorOptions="editorSettings" v-model="blogHTML" useCustomImageHandler
          @image-added="imageHandler" />
      </div>
      <div class="blog-actions">
        <button @click="uploadBlog">Guardar Blog</button>
        <router-link v-if="true" class="router-button" to="blog-preview">
          Vista Previa
        </router-link>
      </div>

    </div>
  </div>
</template>

<script>
import BlogCoverPreview from '@/components/blog/BlogCoverPreview.vue';
import TagInput from '@/components/blog/TagInput.vue';
import Quill from 'quill';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import db from '../assets/firebase/firebaseInit';
import urlSlug from 'url-slug';

window.Quill = Quill;
const ImageResize = require('quill-image-resize-module').default;
Quill.register('modules/imageResize', ImageResize);

let Size = Quill.import('attributors/style/size');
Size.whitelist = ['30px'];
Quill.register(Size, true);

export default {
  metaInfo() {
    return {
      title: 'Create Post',
      meta: [
        {
          name: 'description',
          content:
            'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
        },
        {
          property: 'og:title',
          content: 'Create Post',
        },
        { property: 'og:site_name', content: 'Vanglar' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  data() {
    return {
      loading: false,
      file: null,
      error: null,
      errorMsg: null,
      editorSettings: {
        modules: {
          imageResize: {},
          /*
          toolbar: [
            [{ size: ['30px'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [
              { align: '' },
              { align: 'center' },
              { align: 'right' },
              { align: 'justify' },
            ],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ color: [] }, { background: [] }],
            ['link', 'image', 'video'],
            ['clean'],
          ],
          */
        },
      },
    };
  },
  methods: {
    getTags(values) {
      this.blogTags = values;
    },
    fileChange() {
      this.file = this.$refs.blogPhoto.files[0];
      const fileName = this.file.name;
      this.$store.commit('fileNameChange', fileName);
      this.$store.commit('createFileURL', URL.createObjectURL(this.file));
    },
    openPreview() {
      this.$store.commit('openPhotoPreview');
    },
    imageHandler(file, Editor, cursorLocation, resetUploader) {
      const storageRef = firebase.storage().ref();
      const docRef = storageRef.child(`documents/blogPostPhotos/${file.name}`);
      docRef.put(file).on(
        'state_changed',
        (snapshot) => {
          console.log(snapshot);
        },
        (err) => {
          console.log(err);
        },
        async () => {
          const downloadURL = await docRef.getDownloadURL();
          Editor.insertEmbed(cursorLocation, 'image', downloadURL);
          resetUploader();
        }
      );
    },
    async uploadBlog() {
      if (this.blogTitle.length !== 0 && this.blogHTML.length !== 0) {
        if (this.$store.state.blogPhotoFileURL) {
          this.loading = true;
          const response = await fetch(this.$store.state.blogPhotoFileURL);
          const blob = await response.blob();
          const imageFile = new File([blob], 'image.jpg', { type: blob.type });
          const storageRef = firebase.storage().ref();
          const docRef = storageRef.child(
            `documents/BlogCoverPhotos/${this.$store.state.blogPhotoName}`
          );
          docRef.put(imageFile).on(
            'state_changed',
            (snapshot) => {
              console.log(snapshot);
            },
            (err) => {
              console.log(err);
            },
            async () => {
              //let urlTitle = this.blogTitle.replace(/ /g, '-');
              let urlTitle = urlSlug(this.blogTitle);
              const downloadURL = await docRef.getDownloadURL();
              const timestamp = await Date.now();
              const dataBase = await db.collection('blogPosts').doc(urlTitle);
              await dataBase.set({
                blogID: dataBase.id,
                blogHTML: this.blogHTML,
                blogCoverPhoto: downloadURL,
                blogCoverPhotoName: this.blogPhotoName,
                blogTitle: this.blogTitle,
                blogTags: this.blogTags ? this.blogTags : [],
                blogAuthor: this.blogAuthor,
                blogCategory: this.blogCategory,
                blogShortDescription: this.blogShortDescription,
                blogPublished: false,
                profileId: this.profileId,
                date: timestamp,
                author:
                  this.$store.state.profileFirstName +
                  ' ' +
                  this.$store.state.profileLastName,
              });
              await this.$store.dispatch('getPost');
              this.$router.push({
                name: 'editBlog',
                params: { blogid: urlTitle },
              });
            }
          );
          return;
        }
        this.error = true;
        this.errorMsg = 'Asegúrate que agregaste la imagen de portada';
        setTimeout(() => {
          this.error = false;
        }, 5000);
        return;
      }
      this.error = true;
      this.errorMsg = 'Asegúrate que el título y el contenido se ha agregado.';
      setTimeout(() => {
        this.error = false;
      }, 5000);
      this.loading = false;
      return;
    },
  },
  computed: {
    admin() {
      return this.$store.state.profileAdmin;
    },
    profileId() {
      return this.$store.state.profileId;
    },
    blogPhotoName() {
      return this.$store.state.blogPhotoName;
    },
    blogCategories() {
      return Object.keys(this.$store.state.blogCategories);
    },
    blogTitle: {
      get() {
        return this.$store.state.blogTitle;
      },
      set(payload) {
        this.$store.commit('updateBlogTitle', payload);
      },
    },
    blogTags: {
      get() {
        return this.$store.state.blogTags;
      },
      set(payload) {
        this.$store.commit('updateBlogTags', payload);
      },
    },
    blogAuthor: {
      get() {
        return this.$store.state.blogAuthor;
      },
      set(payload) {
        this.$store.commit('updateBlogAuthor', payload);
      },
    },
    blogCategory: {
      get() {
        return this.$store.state.blogCategory;
      },
      set(payload) {
        this.$store.commit('updateBlogCategory', payload);
      },
    },
    blogShortDescription: {
      get() {
        return this.$store.state.blogShortDescription;
      },
      set(payload) {
        this.$store.commit('updateBlogShortDescription', payload);
      },
    },
    blogHTML: {
      get() {
        return this.$store.state.blogHTML;
      },
      set(payload) {
        this.$store.commit('newBlogPost', payload);
      },
    },
  },
  components: { BlogCoverPreview, TagInput },
};
</script>

<style lang="scss" scoped>
.create-post {
  position: relative;
  height: 100%;

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000065;
    z-index: 10;
  }

  button {
    margin-top: 0;
  }

  .router-button {
    text-decoration: none;
    color: white;
  }

  label,
  button,
  .router-button {
    transition: 0.5s ease-in-out all;
    align-self: center;
    font-size: 14px;
    cursor: pointer;
    border-radius: 20px;
    padding: 12px 24px;
    color: white;
    background-color: #303030;
    text-decoration: none;

    &:hover {
      background-color: rgba(48, 48, 48, 0.7);
    }
  }

  .container {
    position: relative;
    height: 100%;
    padding: 10px 25px 60px;
  }

  //error class
  .invisible {
    opacity: 0 !important;
  }

  .err-message {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    color: white;
    margin-bottom: 10px;
    background-color: #303030;
    opacity: 1;
    transition: 0.5s ease all;

    p {
      font-size: 14px;
    }

    span {
      font-weight: 600;
    }
  }

  .optional-inputs {
    display: flex;
    align-items: center;
    gap: 2rem;

    .tag-input {
      width: 45%;
    }
    .author-input {
      width: 25%;
      transition: 0.5s ease-in-out all;
      padding: 10px 4px;
      border: none;
      border-bottom: 1px solid #303030;

      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #303030;
      }
    }
    .categories {
      width: 30%;
    }
  }

  .blog-info {
    display: flex;
    margin-bottom: 32px;

    input:nth-child(1) {
      min-width: 300px;
    }

    input {
      transition: 0.5s ease-in-out all;
      padding: 10px 4px;
      border: none;
      border-bottom: 1px solid #303030;

      &:focus {
        outline: none;
        box-shadow: 0 1px 0 0 #303030;
      }
    }

    .upload-file {
      flex: 1;
      margin-left: 16px;
      position: relative;
      display: flex;
      align-items: center;

      input {
        display: none;
      }

      .preview {
        margin-left: 16px;
        text-transform: initial;
      }

      span {
        font-size: 12px;
        margin-left: 16px;
      }

      label {
        margin-bottom: 0;
      }
    }
  }

  .short-description {
    margin-bottom: 1rem;
    position: relative;
    outline: none;

    .input-counter {
      color: #b5b5b5;
      position: absolute;
      right: 5px;
      bottom: -10px;
    }
  }

  .editor {
    height: 60vh;
    display: flex;
    flex-direction: column;

    .quillWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .ql-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: scroll;
    }

    .ql-editor {
      padding: 20px 16px 30px;
    }
  }

  .blog-actions {
    margin-top: 60px;

    button {
      margin-right: 16px;
    }
  }
}

.button-inactive {
  cursor: auto !important;
  background-color: #858585 !important;
}
</style>
