/* eslint-disable prettier/prettier */
import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ComplianceView from '../views/ComplianceView.vue';
import CapacitacionView from '../views/CapacitacionView.vue';
import LevantamientoView from '../views/LevantamientoView.vue';
import BlogsView from '../views/BlogsView.vue';
import BlogsAdmin from '../views/BlogsAdmin.vue';
import CreatePostView from '../views/CreatePostView.vue';
import BlogPreview from '../views/BlogPreview.vue';
import ViewBlog from '../views/ViewBlog.vue';
import EditBlog from '../views/EditPostView.vue';
import LoginView from '../views/LoginView.vue';
import SignUpView from '../views/SignUpView.vue';
import AnaliticaDatosView from '../views/AnaliticaDatosView.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import Nosotros from '../views/NosotrosView.vue';
import Contacto from '../views/ContactoView.vue';
import Profile from '../views/ProfileView.vue';
import Admin from '../views/AdminView.vue';
import Privacy_policy from '../views/legal/PrivacyPolicyView.vue';
import Terminos_condiciones from '../views/legal/TerminosYCondicionesView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    // /search/screens -> /search?q=screens
    path: '/home',
    redirect: () => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/' };
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/compliance',
    name: 'compliance',
    component: ComplianceView,
  },
  {
    path: '/capacitacion',
    name: 'capacitacion',
    component: CapacitacionView,
  },
  {
    path: '/levantamiento',
    name: 'levantamiento',
    component: LevantamientoView,
  },
  {
    path: '/analiticaDatos',
    name: 'analiticaDatos',
    component: AnaliticaDatosView,
  },
  {
    path: '/nosotros',
    name: 'nosotros',
    component: Nosotros,
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: Contacto,
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: BlogsView,
  },
  {
    path: '/blogs-admin',
    name: 'blogsAdmin',
    component: BlogsAdmin,
  },
  {
    path: '/create-post',
    name: 'createPost',
    component: CreatePostView,
  },
  {
    path: '/blog-preview',
    name: 'blogPreview',
    component: BlogPreview,
  },
  {
    path: '/view-blog/:blogid',
    name: 'viewBlog',
    component: ViewBlog,
  },
  {
    path: '/edit-blog/:blogid',
    name: 'editBlog',
    component: EditBlog,
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUpView,
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
  },
  {
    path: '/privacy_policy',
    name: 'privacy_policy',
    component: Privacy_policy
  },
  {
    path: '/terminos_y_condiciones',
    name: 'Terminos_Condiciones',
    component: Terminos_condiciones
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
});

export default router;
