<template>
  <b-container>
    <h1 class="mt-10">
      <u>
        AVISO DE PRIVACIDAD INTEGRAL DE VANGLAR EN SU CARÁCTER DE ENCARGADO DEL
        TRATAMIENTO DE LOS DATOS PERSONALES
      </u>
    </h1>

    <h3 class="text_body">
      Vanglar, Sociedad Anónima Promotora de Inversión de Capital Variable
      (VANGLAR, S.A.P.I. de C.V.), también conocida como VANGLAR, con domicilio
      en avenida Patriotismo 767, interior 601-A, colonia San Juan, en Ciudad de
      México, alcaldía Benito Juárez, C.P. 03730, en México, con portal de
      internet www.vanglar.com y de conformidad con los artículos 49 y 50 del
      Reglamento de la Ley Federal de Protección de Datos Personales en Posesión
      de los Particulares (RLFPDPPP), es el <strong>ENCARGADO</strong> de la
      remisión de los datos personales por solicitud del cliente que es
      responsable del uso y protección de sus datos personales conocido como
      <strong>PODER CIUDADANO</strong> a través de su representante Arturo
      Olivier, en adelante “<strong>EL RESPONSABLE</strong>”, y al respecto le
      informamos lo siguiente:
    </h3>

    <h3 class="text_body">
      <strong>¿Para qué fines utilizaremos sus datos personales?</strong>
    </h3>
    <h3 class="text_body">
      Los datos personales que recabamos de usted los utilizaremos para las
      siguientes finalidades que son necesarias para brindarle el siguiente
      servicio:
    </h3>
    <h3 class="text_body">
      <dd>• Educación electoral</dd>
      <dd>• Información económica de su comunidad</dd>
      <dd>• Actualización de los partidos políticos</dd>
      <dd>
        • Registro y emisión de la credencial virtual como afiliado de PODER
        CIUDADANO
      </dd>
    </h3>

    <h3 class="text_body">
      En caso de que no desee que sus datos personales sean tratados para estos
      fines, desde este momento usted puede comunicar lo anterior a través del
      mecanismo señalado en el aviso de privacidad integral de “<strong
        >EL RESPONSABLE</strong
      >”.
    </h3>

    <h3 class="text_body">
      <strong>¿Qué datos personales utilizaremos para estos fines?</strong>
    </h3>
    <h3 class="text_body">
      Con fundamento en el artículo 51 del RLFPDPPP y la relación que guarda el
      encargado y el responsable, para llevar a cabo las finalidades descritas
      en el presente aviso de privacidad, utilizaremos los siguientes datos
      personales:
    </h3>

    <h3 class="text_body">
      <dd>• Número de registro del ciudadano que te invitó</dd>
      <dd>• Nombre completo</dd>
      <dd>• Correo electrónico</dd>
      <dd>• Número telefónico de celular</dd>
      <dd>• Género</dd>
      <dd>• Edad</dd>
      <dd>• Dirección</dd>
      <dd>• Estado</dd>
      <dd>• Código Postal</dd>
      <dd>• Sección Electoral</dd>
      <dd>• Clave de elector</dd>
      <dd>• Fotografía</dd>
    </h3>

    <h3 class="text_body">
      <strong
        >¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
        oponerse a su uso?</strong
      >
    </h3>

    <h3 class="text_body">
      Usted tiene derecho a conocer qué datos personales tenemos de usted, para
      qué los utilizamos y las condiciones del uso que les damos (Acceso).
      Asimismo, es su derecho solicitar la corrección de su información personal
      en caso de que esté desactualizada, sea inexacta o incompleta
      (Rectificación); que la eliminemos de nuestros registros o bases de datos
      cuando considere que la misma no está siendo utilizada adecuadamente
      (Cancelación); así como oponerse al uso de sus datos personales para fines
      específicos (Oposición). Estos derechos se conocen como derechos ARCO.
    </h3>

    <h3 class="text_body">
      Para el ejercicio de cualquiera de los derechos ARCO, usted deberá
      presentar la solicitud respectiva a través del medio señalado en el aviso
      de privacidad integral emitido por “<strong>EL RESPONSABLE</strong>” del
      tratamiento de sus datos que en este caso es PODER CIUDADANO, de
      conformidad con los capítulos III y IV de la Ley Federal de Protección de
      Datos Personales en Posesión de Particulares.
    </h3>

    <h3 class="text_body">
      <strong
        >¿Cómo puede limitar el uso o divulgación de su información
        personal?</strong
      >
    </h3>
    <h3 class="text_body">
      Con objeto de que usted pueda limitar el uso y divulgación de su
      información personal, usted deberá presentar la solicitud respectiva a
      través del medio señalado en el aviso de privacidad integral emitido por
      “<strong>EL RESPONSABLE</strong>” del tratamiento de sus datos que en este
      caso es PODER CIUDADANO, de conformidad con los capítulos III y IV de la
      Ley Federal de Protección de Datos Personales en Posesión de Particulares.
    </h3>

    <h3 class="text_body">
      <strong
        >¿Cómo puede conocer los cambios en este aviso de privacidad?</strong
      >
    </h3>
    <h3 class="text_body">
      El presente aviso de privacidad puede sufrir modificaciones, cambios o
      actualizaciones derivadas de nuevos requerimientos legales; de las
      necesidades requeridas por “<strong>EL RESPONSABLE</strong>”; de nuestras
      prácticas de privacidad; o por otras causas.
    </h3>

    <h3 class="text_body">
      Nos comprometemos a mantenerlo informado sobre los cambios que pueda
      sufrir el presente aviso de privacidad, a través de: www.vanglar.com en
      nuestra condición de encargado del tratamiento de sus datos personales.
    </h3>

    <h3 class="text_body">
      El procedimiento a través del cual se llevarán a cabo las notificaciones
      sobre cambios o actualizaciones al presente aviso de privacidad se
      realizará al ingresar a nuestra página de internet donde se desplegará un
      aviso invitándole a leer nuestro Aviso de Privacidad vigente.
    </h3>

    <h3 class="text_body right">
      <strong>ULTIMA ACTUALIZACIÓN 25 DE ENERO DE 2021. </strong>
    </h3>

    <br />
    <br />
    <h3 class="text_body"><strong>GLOSARIO DE TÉRMINOS </strong></h3>
    <h3 class="text_body">
      De conformidad con los artículos 3 fracciones IX, XIV, XVII de la LFPDPPP
      y 2 fracciones III y IX del RLFPDPPP, se señalan los siguientes términos
      para mayor comprensión del presente Aviso de Privacidad.
    </h3>

    <h3 class="text_body">
      <dd>
        <strong>- ENCARGADO:</strong> La persona física o jurídica que sola o
        conjuntamente con otras trate datos personales por cuenta del
        responsable.
      </dd>
      <dd>
        <strong>- RESPONSABLE:</strong> Persona física o moral de carácter
        privado que decide sobre el tratamiento de datos personales.
      </dd>

      <dd>
        <strong>- TITULAR:</strong> La persona física a quien corresponden los
        datos personales.
      </dd>

      <dd>
        <strong>- ENTORNO DIGITAL:</strong> Es el ámbito conformado por la
        conjunción de hardware, software, redes, aplicaciones, servicios o
        cualquier otra tecnología de la sociedad de la información que permiten
        el intercambio o procesamiento informatizado o digitalizado de datos.
      </dd>

      <dd>
        <strong>- REMISIÓN:</strong> La comunicación de datos personales entre
        el responsable y el encargado, dentro o fuera del territorio mexicano.
      </dd>
    </h3>
  </b-container>
</template>

<script>
export default {
  name: 'privacy_policy',
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style>
.text_body {
  font-size: 20px;
  text-align: justify;
  margin: 30px;
}
dd {
  margin-left: 40px;
}

.right {
  text-align: right !important;
}
</style>
