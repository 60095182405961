<template>
  <b-container>
    <h1 class="title mt-15">Términos y Condiciones</h1>
    <h3 class="text_body">
      Vanglar S.A.P.I de C.V es una empresa mexicana constituida legalmente en
      noviembre del año 2017, y que comercializa la aplicación del mismo nombre.
      Vanglar apoya y entrega capacitación, comunicación interna, operación,
      seguimiento y análisis de personas y procesos en la empresa en una sola
      aplicación y ajustándose a las necesidades de sus clientes.
    </h3>

    <h3 class="text_body">
      Todos los datos adquiridos se convierten en información mostrada en
      tableros de control para la toma de decisiones a lo largo y ancho de la
      organización; además de nutrirse de la información de Vanglar, puede
      también hacerlo de aquella que generan otros sistemas de información de la
      institución.
    </h3>

    <h3 class="text_body">
      Le informamos que es importante y obligatorio que, en su carácter de
      Visitante y/o Usuario, en adelante el “USUARIO”, y Cliente, en adelante el
      “CLIENTE”, lea, entienda y acepte de manera total el contenido de este
      documento, dicho consentimiento se considera como otorgado a favor de
      VANGLAR S.A.P.I. de C.V., en adelante “Vanglar”.
    </h3>

    <h3 class="text_body">
      Al consultar, registrarse, solicitar y/o usar los servicios (aun
      gratuitamente), denominado en lo subsecuente como el “SERVICIO”, que se
      presentan a través del sitio web y/o la aplicación móvil “Vanglar”,
      denominadas en lo subsecuente como “LA PLATAFORMA”, implica la adhesión a
      estos Términos y Condiciones de Uso y del Servicio.
    </h3>

    <h3 class="text_body">
      La importancia de que el Usuario y/o Cliente conozca el contenido de estos
      términos y condiciones radica en que estos disponen las reglas
      establecidas por Vanglar para el uso de LA PLATAFORMA en los Estados
      Unidos Mexicanos, así como los derechos y obligaciones que tiene el
      Usuario y/o Cliente como consumidor, salvo las excepciones previstas en
      diversas cláusulas.
    </h3>

    <h3 class="text_body">
      El marco jurídico que en los Estados Unidos Mexicanos protege los derechos
      y obligaciones tanto de Vanglar como del Usuario y/o Cliente se rigen por
      la legislación mexicana, específicamente la Ley Federal de Protección al
      Consumidor, el Código de Comercio, el Código Civil Federal, el Código
      Federal de Procedimientos Civiles y la Norma Mexicana
      ‘NMXCOE-001-SCFI-2018, Comercio Electrónico - Disposiciones a las que se
      sujetarán aquellas personas que ofrezcan, comercialicen o vendan bienes,
      productos o servicios’, la legislación mexicana señalada en su conjunto
      reglamenta el uso de servicios en línea para establecer tanto
      disposiciones normativas como sanciones en caso de que se realice la
      violación a los derechos de los consumidores en línea, por lo cual si
      usted considera que sus derechos como Usuario y/o Cliente no están siendo
      protegidos bajo dicho marco legal, deberá conciliar inicialmente las
      quejas y reclamaciones del SERVICIO con Vanglar utilizando los medios que
      indican en estos términos y condiciones y, en caso de no obtener una
      respuesta satisfactoria, el Usuario y/o Cliente pueden utilizar los
      mecanismos establecidos en la Ley ante la Procuraduría Federal del
      Consumidor.
    </h3>

    <h3 class="text_body">
      <strong
        >Si El Usuario y/o Cliente no está de acuerdo, le solicitamos abstenerse
        de realizar cualquier tipo de uso de los servicios y/o contenidos y/o
        provistos en LA PLATAFORMA.</strong
      >
    </h3>

    <h3 class="text_body">
      <strong>IDENTIFICACIÓN DEL PRESTADOR DEL SERVICIO</strong>
    </h3>

    <div>
      <v-row align="center" justify="center">
        <v-col class="col-3 text-center">
          <span class="text-button">
            <strong>Razón social</strong>
          </span>
        </v-col>
        <v-col class="col-9"> VANGLAR, S.A.P.I. de C.V. </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col class="col-3 text-center">
          <span class="text-button">
            <strong>Nombre</strong>
          </span>
        </v-col>
        <v-col class="col-9"> comercial VANGLAR</v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col class="col-3 text-center">
          <span class="text-button">
            <strong>RFC</strong>
          </span>
        </v-col>
        <v-col class="col-9">VAN-171101-442</v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col class="col-3 text-center">
          <span class="text-button">
            <strong>Giro</strong>
          </span>
        </v-col>
        <v-col class="col-9"
          >Servicios de capacitación, comunicación interna, operación,
          seguimiento y análisis de personas y procesos en la empresa.</v-col
        >
      </v-row>
      <v-row align="center" justify="center">
        <v-col class="col-3 text-center">
          <span class="text-button">
            <strong>Teléfono</strong>
          </span>
        </v-col>
        <v-col class="col-9"> 55 1370 7787</v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col class="col-3 text-center">
          <span class="text-button">
            <strong>Domicilio</strong>
          </span>
        </v-col>
        <v-col class="col-9">
          Av. Patriotismo 767 int. 601-A; Colonia. San Juan; Alcaldía Benito
          Juárez; CP 03730; Ciudad de México, México.</v-col
        >
      </v-row>
      <v-row align="center" justify="center">
        <v-col class="col-3 text-center">
          <span class="text-button">
            <strong>Contacto</strong>
          </span>
        </v-col>
        <v-col class="col-9">
          Correo electrónico: victor.quiroz@vanglar.com</v-col
        >
      </v-row>
      <v-row align="center" justify="center">
        <v-col class="col-3 text-center">
          <span class="text-button">
            <strong>Horario de atención</strong>
          </span>
        </v-col>
        <v-col class="col-9">
          Lunes a viernes de 10:00 hrs. -18:00 hrs.<br />
          Sábado y domingo de 10:00 hrs. -14:00 hrs.</v-col
        >
      </v-row>
    </div>

    <div style="height: 150px"></div>
    <h2><strong>CONDICIONES GENERALES</strong></h2>
    <h3 class="text_body">
      <strong>a. Capacidad legal para la contratación del servicio</strong>
    </h3>
    <h3 class="text_body">
      Previo a realizar cualquier uso en LA PLATAFORMA de Vanglar el Usuario y/o
      Cliente declara bajo protesta de decir verdad que tiene cumplidos al menos
      18 años y está en pleno ejercicio de sus derechos y facultades para
      obligarse en términos de la legislación mexicana.
    </h3>

    <h3 class="text_body">
      <strong>Respecto del usuario:</strong> Si aún no cuenta con esa edad, el
      Usuario deberá realizar el registro a través de su madre, padre o tutor,
      entendiéndose que este será responsable de las actividades realizadas en
      LA PLATAFORMA web.
    </h3>

    <h3 class="text_body">
      <strong
        >Cualquier uso o acceso realizado por personas menores de 13 años con o
        sin consentimiento de su madre, padre o tutor</strong
      >
      queda limitado a no ser sujeto de derechos.
    </h3>

    <h3 class="text_body">
      En caso de no ser mayor de edad ni contar con la autorización de madre,
      padre o tutor, cualquier acción realizada en LA PLATAFORMA carece de
      validez legal y, por ende, Vanglar se reserva el derecho de anular
      operaciones realizadas.
    </h3>

    <h3 class="text_body">
      <strong>Respecto del cliente:</strong> Si aún no cuenta con esa edad, no
      se proveerá el SERVICIO de LA PLATAFORMA, por lo cual en caso de que
      Vanglar identifique que el Cliente es menor a esa edad, se reserva el
      derecho de anular operaciones realizadas.
    </h3>

    <h3 class="text_body">
      <strong>b. Descripción del servicio y sus contenidos</strong>
    </h3>
    <h3 class="text_body"><strong>El servicio</strong></h3>
    <h3 class="text_body">
      <strong>Vanglar</strong> ofrece una plataforma educativa a través de la
      cual se ofrecen diversos servicios, disponibles para los Usuarios y/o
      Clientes con un Plan de Suscripción Activo, los cuales se enlistan a
      continuación:
    </h3>

    <h3 class="text_body">
      i. Capacitación a través de manuales, libros, guías, videos etc., cuyo
      formato puede ser online en el sitio web o con contenido descargado en la
      aplicación móvil;<br />
      ii. Capacitación a través de cursos y en líneas de especialización de
      competencias en ramas específicas;<br />
      iii. Lectura y descarga de material educativo de apoyo para la
      capacitación, capacitación a través de cursos y líneas de
      especialización;<br />
      iv. Encuestas de satisfacción;<br />
      v. Notificaciones relativas al porcentaje de avance en la
      capacitación;y<br />
      vi. Generar constancia o diploma de finalización de la capacitación, de
      los cursos y/o líneas de especialización ofrecidos
    </h3>

    <h3 class="text_body">
      Los servicios que se indican pueden ser modificados o retirados por
      Vanglar en cualquier momento, siempre con el fin de mejorar nuestra oferta
      de servicios a nuestros Usuarios y/o Clientes.
    </h3>

    <h3 class="text_body"><strong>Contenido</strong></h3>
    <h3 class="text_body">
      El contenido que ofrece Vanglar en sus cursos de capacitación y líneas de
      especialización es proporcionado por el Cliente, cada uno en su ramo, a
      través del cual se transmite conocimiento práctico al Usuario.
    </h3>

    <h3 class="text_body">
      Vanglar junto con su equipo de trabajo y sus colaboradores externos
      realiza todos los esfuerzos necesarios para ofrecer contenido actualizado
      en LA PLATAFORMA, sin embargo, respecto del contenido ofrecido para cada
      una de las ramas de capacitación estamos sujetos a que los Clientes
      entreguen el mismo, para realizar dicho proceso, bajo este entendido es
      probable que algunos cursos y/o líneas de especialización presenten
      contenido que aún no haya sido actualizado.
    </h3>

    <h3 class="text_body">
      El uso del contenido proporcionado por el Cliente se ofrece bajo una
      licencia de uso, el detalle al respecto lo puedes consultar en la sección
      denominada ‘Propiedad Intelectual’.
    </h3>

    <h3 class="text_body">La capacitación ofrecida en Vanglar no implica:</h3>

    <h3 class="text_body">
      i. La impartición personalizada de cursos en línea bajo un modelo de
      sistema escolarizado, esto es: <br />
      <dd>
        •Vanglar no tiene horarios definidos de clases ya que los cursos y/o
        líneas de especialización están disponibles en todo momento.
      </dd>
      <dd>
        • Vanglar no ofrece ninguna cobertura académica curricular oficial, sin
        embargo, el CLIENTE (La empresa) extiende reconocimientos con valor
        curricular interno.
      </dd>
      <dd>
        • Vanglar no ofrece una metodología tradicional de enseñanzaaprendizaje
        que de retroalimentación en tiempo real de conocimientos adquiridos en
        forma individual al Usuario.
      </dd>
    </h3>

    <h3 class="text_body">
      ii. La impartición de cursos y/o líneas de especialización escolarizados
      con validez oficial en México ante la Secretaria de Educación Pública o
      cualquier otra Institución que tenga la autorización oficial para otorgar
      reconocimientos con validez oficial.
    </h3>

    <h3 class="text_body">
      iii. La obtención de un título profesional o grado académico con
      Reconocimiento de Validez Oficial de Estudios en México (RVOE).
    </h3>

    <h3 class="text_body">
      <strong>c. Alcance del servicio con respecto a la licencia de uso</strong>
    </h3>
    <h3 class="text_body">
      El Cliente otorga a Vanglar una licencia de uso respecto a los contenidos
      que le proporciona para efectos de capacitación , así como Vanglar otorga
      una licencia de uso en favor del Usuario para el uso de la de la
      plataforma web y la aplicación móvil, ésta licencia es de carácter
      gratuita, no exclusiva, revocable y no transferible, la cual queda sujeta
      y limitada al uso personal del Usuario, ya sea para tomar una decisión de
      adquisición de los servicios ofrecidos a través de LA PLATAFORMA o para
      compartir en sus redes sociales o a través de otros mecanismos la
      experiencia obtenida sólo con fines de recomendación.
    </h3>

    <h3 class="text_body">
      El alcance de la licencia en mención no autoriza al Usuario a obtener un
      beneficio económico derivado del uso del contenido proveído, ni a realizar
      cualquier acción tecnológica que permita (ni apoyar los intentos de otros)
      eludir, aplicar ingeniería inversa, descifrar, descompilar, desmontar u
      obtener el código fuente de LA PLATAFORMA. Adicionalmente en la sección de
      Obligaciones del Usuario se establecen otras limitaciones a la licencia
      otorgada y a las acciones permitidas al Usuario.
    </h3>

    <h3 class="text_body">
      <strong>REGLAS DE CONTRATACIÓN EN LA PLATAFORMA</strong>
    </h3>
    <h3 class="text_body"><strong>a. Alta de cuenta de usuario</strong></h3>

    <h3 class="text_body">
      Para el acceso a los servicios generales de Vanglar no será necesario el
      registro de los visitantes a LA PLATAFORMA, sin embargo, para determinados
      servicios como inscribirse a cursos y/o líneas de especialización, es
      necesaria la creación de un perfil de Usuario, para ello deberá realizar
      el siguiente procedimiento:
    </h3>

    <h3 class="text_body">
      <dd>
        •Indicar una dirección de correo electrónico en la sección “Suscríbete”
      </dd>
      <dd>
        •Una vez confirmado, el usuario deberá seleccionar la forma en que
        ingresará a la Plataforma, para ello está disponible la posibilidad de
        ingresar con su cuenta de con su Correo electrónico, cuenta de Facebook,
        cuenta Google o con la combinación de su cuenta de correo electrónico y
        contraseña asignada por el Usuario.
      </dd>
    </h3>

    <h3 class="text_body">
      Si el usuario continúa el proceso autenticándose con Facebook
    </h3>

    <h3 class="text_body">
      <dd>
        •Vanglar conectará al Usuario a la plataforma de Facebook en el cual se
        solicitará: Usuario o Correo electrónico y Contraseña que tiene
        configurado como cuenta de acceso en dicha red social
      </dd>
      <dd>
        •Al utilizar Facebook como mecanismo de acceso a LA PLATAFORMA, Vanglar
        podrá ver tu nombre de perfil, fotografía y cuenta de correo electrónico
      </dd>
      <dd>
        •Vanglar no recabará los datos de acceso del Usuario a su cuenta de
        Facebook ni tiene permisos para publicar en dicha red social a nombre
        del Usuario
      </dd>
    </h3>

    <h3 class="text_body">
      Si el usuario continúa con el proceso autenticándose con la asignación de
      un Usuario y contraseña
    </h3>

    <h3 class="text_body">
      <dd>• Indicar Nombre de Usuario y/o correo electrónico</dd>
      <dd>• Indicar Contraseña</dd>
    </h3>

    <h3 class="text_body">
      Una vez que Vanglar haya realizado satisfactoriamente el proceso de
      registro, le brindará acceso automático al Usuario a LA PLATAFORMA.
    </h3>

    <h3 class="text_body">
      Es importante que cuide el uso de sus datos de autenticación para acceder
      a LA PLATAFORMA, como son su ‘Usuario’, correo electrónico y contraseña
      que asigne al realizar su procedimiento de alta, así como las
      actualizaciones y/o modificaciones posteriores a dichos datos, por lo cual
      toda acción realizada en LA PLATAFORMA, le serán atribuidas a la persona
      relacionada con el Usuario registrado.
    </h3>

    <h3 class="text_body">
      Si comparte sus datos o credenciales para que otra persona inicie sesión
      en su perfil de Usuario, acepta que asumirá toda la responsabilidad de
      cualquier actividad que sea realizada en su cuenta. Si El Usuario descubre
      que otra persona está utilizando su cuenta sin su permiso o bien sospecha
      que se ha producido alguna otra infracción de seguridad, debe ponerse en
      contacto inmediatamente con Vanglar para que tome las medidas necesarias.
    </h3>

    <h3 class="text_body"><strong>b. Cancelación de la suscripción</strong></h3>
    <h3 class="text_body">
      La cancelación de la cuenta tiene como finalidad que el Usuario solicite
      el NO acceso al contenido específico que le brinda LA PLATAFORMA. Para
      solicitar esta cancelación requerimos que el Usuario remita, un escrito
      libre al correo electrónico victor.quiroz@vanglar.com con el asunto
      “Solicitud de cancelación”. Una vez recibida la solicitud, Vanglar
      notificará al CLIENTE, contestación que será comunicada al Usuario vía
      correo electrónico a la dirección registrada como Usuario en LA
      PLATAFORMA.
    </h3>

    <h3 class="text_body">
      <strong
        >c. Suspensión temporal de la cuenta a solicitud del usuario</strong
      >
    </h3>

    <h3 class="text_body">
      El Usuario tiene la opción de solicitar que los servicios brindados en LA
      PLATAFORMA sean pausados por un determinado tiempo, deberá enviar un
      correo electrónico a victor.quiroz@vanglar.com para que Vanglar notifique
      inmediatamente al CLIENTE, y este haga una evaluación de su solicitud y le
      notifique la procedencia de ésta vía correo electrónico a la dirección
      registrada como Usuario y será de conformidad con las siguientes
      condiciones:
    </h3>

    <h3 class="text_body">
      <dd>
        • El Usuario deberá mediante el correo electrónico que el Usuario
        designó al realizar su registro en LA PLATAFORMA su solicitud para
        pausar los servicios al correo victor.quiroz@vanglar.com o a cualquiera
        de los medios de contacto señalados en el apartado de Identificación de
        los presentes Términos y Condiciones, indicando su nombre completo y
        nombre de Usuario
      </dd>
      <dd>
        • Una vez procedente la suspensión temporal de la cuenta, Vanglar
        procederá a pausar los servicios del Usuario y le notificará la
        procedencia a través de la dirección de correo electrónico registrada
        por el Usuario en nuestra plataforma
      </dd>
      <dd>
        • Durante esta pausa el Usuario no podrá hacer uso de nuestros
        servicios, sin embargo, tiene la opción de solicitar la reactivación de
        su cuenta en cualquier momento
      </dd>
      <dd>
        • El Usuario acepta que al momento de que se realice la reactivación de
        su servicio no será procedente el ajuste de la vigencia de la
        suscripción según corresponda
      </dd>
    </h3>

    <h3 class="text_body"><strong>OBLIGACIONES DE VANGLAR</strong></h3>
    <h3 class="text_body">
      Para brindarle un mejor servicio Vanglar se compromete a brindar un
      servicio de calidad, en apego a las siguientes condiciones, así como a las
      demás establecidas en estos Términos y Condiciones:

      <dd>
        • Mantener un contenido fehaciente de los servicios que se ofrecen en LA
        PLATAFORMA e informar oportunamente al Usuario y/o Cliente de cualquier
        cambio al alcance de los Servicios, Términos y Condiciones y/o Aviso de
        Privacidad, a través de los medios habilitados para tal efecto y
        señalados en diversos apartados
      </dd>
      <dd>
        • Dar cabal cumplimiento a nuestras obligaciones en materia de
        protección de datos personales
      </dd>
      <dd>
        • Estar disponible para utilizar medidas auxiliares de solución de
        conflictos en caso de existir una situación particular con los Usuarios
        y/o Clientes de LA PLATAFORMA
      </dd>
      <dd>
        • Proveer soporte técnico con respecto a LOS SERVICIOS que se ofrecen en
        LA PLATAFORMA, siempre y cuando la solicitud se realice en horario de
        atención de lunes a viernes de 10:00 hrs a 14:00 hrs o a través de los
        medios de contacto señalados en el apartado de Identificación, en caso
        de realizarla en días inhábiles o fuera de los horarios indicados de
        atención se le dará respuesta en un plazo no mayor a 48 horas
      </dd>
      <dd>
        • Registrar y dar seguimiento a los comentarios y/o quejas realizadas
        por el Usuario y/o Clientes.
      </dd>
    </h3>

    <h3 class="text_body">
      <strong>OBLIGACIONES DEL USUARIO Y/O CLIENTE</strong>
    </h3>
    <h3 class="text_body">
      Para hacer un uso eficiente de nuestros servicios, el Usuario y/o Cliente
      se obliga a:
      <dd>
        • Crear una cuenta de Usuario haciendo uso de su identidad real
        proporcionando información verídica, precisa y actualizada
      </dd>
      <dd>
        • Utilizar su cuenta de Usuario en forma responsable, ya que ésta es de
        carácter personal, única e intransferible. La contraseña que el Usuario
        asigne está cifrada, esto es, aun cuando queda guardada en los sistemas
        de información de Vanglar, éste no tiene forma alguna de conocerla, esto
        implica que la contraseña es de exclusivo conocimiento por parte del
        Usuario, por ende, todas las acciones realizadas con la cuenta del
        Usuario le serán atribuibles a éste
      </dd>
      <dd>
        • Descargar y utilizar el material de apoyo y/o documentos que se
        encuentran en LA PLATAFORMA únicamente para uso personal y actividades
        sin fines de lucro
      </dd>
      <dd>
        • Contar el CLIENTE con capacidad natural y jurídica para obligarse a
        las disposiciones contenidas en estos términos y condiciones,
        considerando principalmente ser mayor de edad, no tener una suspensión
        de derechos dictada por alguna autoridad judicial y estar en condiciones
        mentales suficientes que le permitan entender el alcance del contenido
        de este documento
      </dd>
      <dd>
        • Para el caso del CLIENTE, realizar los pagos correspondientes de
        conformidad a los servicios contratados
      </dd>
      <dd>
        • Para el caso del CLIENTE, verificar la fecha de vencimiento de los
        servicios contratados y atender los recordatorios de renovación de
        servicios en tiempo y forma
      </dd>
      <dd>
        • Ser el único responsable de cualquier daño o perjuicio ocasionado a
        terceros y/o a Vanglar consecuencia del uso indebido del contenido y/o
        de LA PLATAFORMA
      </dd>
      <dd>
        • Cumplir con las demás condiciones establecidas en este documento
      </dd>
    </h3>

    <h3 class="text_body">
      <strong
        >USOS PERMITIDOS Y LIMITACIONES DE USO DE LA PLATAFORMA PARA
        USUARIOS</strong
      >
    </h3>
    <h3 class="text_body">
      Para realizar la suscripción a LA PLATAFORMA y hacer uso del contenido
      específico de contratación que se ofrece a través de Vanglar es necesario
      que conozca los usos permitidos y no permitidos, los cuales, de manera
      enunciativa más no limitativa, se indican a continuación:
    </h3>
    <h3 class="text_body">
      <strong>a. Usos Permitidos</strong>

      <dd>
        • Realizar la suscripción a los contenidos específicos de contratación
        ofrecidos en LA PLATAFORMA
      </dd>
      <dd>• Consultar y editar su perfil en forma ilimitada</dd>
      <dd>
        • Consultar y descargar, reproducir o compartir el material al que tiene
        acceso derivado de los derechos que su suscripción le da, siempre y
        cuando sea realizado con fines no lucrativos
      </dd>
      <dd>
        • Hacer mención o publicación en redes sociales o cualquier otro medio
        de carácter no comercial sobre los servicios que ofrecemos en LA
        PLATAFORMA
      </dd>
    </h3>

    <h3 class="text_body">
      <strong>b. Usos No Permitidos</strong>

      <dd>
        • Al realizar alguna actividad de evaluación, solicitar que alguien más
        lo realice en su nombre o utilizar respuestas que le hayan sido
        compartidas por otros Usuarios
      </dd>
      <dd>
        • Copiar, distribuir, descargar, mostrar o transmitir cualquier tipo de
        contenido de este sitio en cualquier forma o a través de cualquier medio
        ya sean medios electrónicos, mecánicos, electromecánicos, ópticos,
        audiovisuales, sonoros o cualquier otro medio superviniente sin
        autorización de Vanglar previa aprobación del CLIENTE
      </dd>
      <dd>
        • Dar de alta cuentas de usuario con información falsa o con datos
        personales de los cuales no es el titular
      </dd>
      <dd>• Utilizar nombres de usuario obscenos, falsos o inmorales</dd>
      <dd>• Vender o transferir su Cuenta de Usuario</dd>
      <dd>
        • Enviar correos electrónicos con asuntos que no estén relacionados con
        LA PLATAFORMA o con atención al cliente
      </dd>
      <dd>
        • Utilizar contenidos de LA PLATAFORMA para revender servicios de
        capacitación o cualquier otro tipo de servicio
      </dd>
      <dd>
        • Hacer cualquier uso contrario dentro de LA PLATAFORMA conforme a lo
        establecido en los presentes Términos y Condiciones
      </dd>
      <dd>
        • Realizar el uso de herramientas automatizadas o tecnologías similares
        con la intención de extraer, obtener o recopilar cualquier información
        contenida en LA PLATAFORMA
      </dd>
    </h3>

    <h3 class="text_body">
      La realización de cualquiera de las actividades no permitidas traerá como
      consecuencia la notificación inmediata al CLIENTE para que imponga las
      sanciones que considere pertinentes, para el caso del último punto traerá
      como consecuencia la cancelación inmediata de la suscripción en LA
      PLATAFORMA y en caso de que la acción realizada esté vinculada con alguna
      actividad ilegal, se le dará parte de dicha situación a las autoridades
      correspondientes.
    </h3>

    <h3 class="text_body">
      <strong
        >USOS PERMITIDOS Y LIMITACIONES DE USO DE LA PLATAFORMA PARA
        CLIENTES</strong
      >
    </h3>
    <h3 class="text_body">
      Para hacer uso de LA PLATAFORMA y de los servicios que se ofrecen a través
      de Vanglar es necesario que conozca los usos permitidos y no permitidos,
      los cuales, de manera enunciativa más no limitativa, se indican a
      continuación:
    </h3>
    <h3 class="text_body"><strong>a. Usos Permitidos</strong></h3>

    <h3 class="text_body">
      <dd>
        • Realizar la contratación de los servicios y/o planes ofrecidos en LA
        PLATAFORMA
      </dd>
      <dd>
        • Compartir en LA PLATAFORMA el material de capacitación derivado de los
        derechos que su contratación le otorga
      </dd>
      <dd>
        • Hacer mención o publicación en redes sociales o cualquier otro medio
        de carácter no comercial sobre los servicios que ofrecemos en LA
        PLATAFORMA
      </dd>
    </h3>

    <h3 class="text_body"><strong>b. Usos No Permitidos</strong></h3>
    <h3 class="text_body">
      <dd>
        • Proporcionar, compartir y/o transmitir contenido que infrinja derechos
        de autor o derechos de propiedad industrial de terceros
      </dd>
      <dd>
        • Compartir links de descarga de contenidos o que dirijan a sitios web
        que alojen links o contenidos que infrinjan derechos de autor o derechos
        de propiedad industrial de terceros
      </dd>
    </h3>

    <h3 class="text_body">
      El Cliente y/o Usuario será responsable de los daños y perjuicios que
      ocasione a Vanglar o a terceros, por:
    </h3>

    <h3 class="text_body">
      <dd>
        • Cualquier acto realizado con dolo o mala fe que afecte tanto en
        aspectos morales como económicos a cualquier persona física o persona
        moral que provoque que sean instauradas acciones legales en contra de
        Vanglar y/o de sus socios
      </dd>
      <dd>
        • La subutilización de los contenidos para replicar servicios de
        capacitación a terceros como consecuencia de la intención de obtener un
        lucro
      </dd>
      <dd>
        • Realizar el registro de los contenidos de LA PLATAFORMA a su nombre o
        a nombre de terceros
      </dd>
      <dd>
        • El uso no autorizado de los registros marcarios otorgados por la
        autoridad a favor de VANGLAR SAPI de CV
      </dd>
      <dd>
        • El uso indebido de LA PLATAFORMA aun y cuando ello no conlleve el daño
        a terceros
      </dd>
      <dd>
        • Introducir en nuestra plataforma cualquier elemento que dañe o afecte
        su funcionamiento en parte o en su totalidad
      </dd>
      <dd>
        • Por cualquier acto u omisión imputables a El Usuario y/o Cliente que
        genere algún tipo de afectación a nuestros sistemas de información, así
        como a la disponibilidad de LA PLATAFORMA
      </dd>
      <dd>• Incumplimiento a estos Términos y Condiciones</dd>
    </h3>

    <h3 class="text_body">
      <strong
        >SUSPENSIÓN DE LA CUENTA POR INFRINGIR LOS TÉRMINOS Y CONDICIONES DEL
        SERVICIO</strong
      >
    </h3>
    <h3 class="text_body">
      Vanglar suspenderá la cuenta del Usuario y cancelara los servicios al
      Cliente si detecta que se ha realizado una violación a cualquiera de las
      disposiciones contenidas en estos Términos y Condiciones por un plazo de
      30 días. Adicionalmente a los Usos señalados como no permitidos en el
      inciso anterior, se consideran causales de suspensión de LOS SERVICIOS, en
      forma enunciativa más no limitativa, las siguientes situaciones:
    </h3>

    <h3 class="text_body">
      <dd>
        • Si se detecta que el Usuario y/o Cliente publica contenido no
        permitido o viola temas relacionados con protección de datos personales
        o propiedad intelectual
      </dd>
      <dd>
        • Si el Usuario y/o Cliente remite correos electrónicos con contenido
        obscenos, difamatorio, agresivo o utiliza un lenguaje inapropiado en las
        solicitudes que envíe a victor.quiroz@vanglar.com
      </dd>
      <dd>
        • Cualquier otra acción que viole cualquier norma jurídica nacional y/o
        internacional
      </dd>
    </h3>

    <h3 class="text_body">
      Si el Usuario identifica que su cuenta ha sido suspendida y/o el Cliente
      identifica la suspensión de los servicios que le provee la PLATAFORMA
      deberá enviar ( para el caso del Usuario, desde la misma dirección
      electrónica utilizada para registrarse) un correo electrónico a
      victor.quiroz@vanglar.com con el asunto: “Cuenta suspendida” a efecto de
      que le sea informado el motivo de suspensión y conocer, en caso de que sea
      procedente, cuál es el proceso para realizar la reactivación de su cuenta.
    </h3>

    <h3 class="text_body">
      El tiempo que dure suspendida la cuenta del USUARIO y/o los servicios al
      CLIENTE por las condiciones establecidas en esta cláusula no será repuesto
      dentro de la vigencia de la suscripción del Cliente salvo en los casos en
      que la suspensión sea consecuencia de un error por parte de Vanglar.
    </h3>

    <h3 class="text_body">
      <strong>LIMITACIÓN Y EXCLUSIÓN DE RESPONSABILIDAD</strong>
    </h3>
    <h3 class="text_body">
      <strong>a. Respecto a los sitios web de terceros</strong>
    </h3>
    <h3 class="text_body">
      Los Sitios Enlazados en LA PLATAFORMA y sus subdominios, tienen solo el
      propósito de facilitar al Usuario la información de Sitios de terceros
      afines a los servicios; debe entenderse que dichas menciones no
      presuponen, ni establecen la existencia de algún tipo de vínculo jurídico
      y/o comercial, responsabilidad, obligación entre LA PLATAFORMA con los
      Sitios de terceros y/o los terceros propietarios de dichos Sitios.
    </h3>

    <h3 class="text_body">
      La exclusión de responsabilidad de Vanglar con respecto a los Sitios de
      terceros incluye:
    </h3>

    <h3 class="text_body">
      <dd>
        •Cualquier tipo de daño informático en los equipos de cómputo del
        Usuario al visitar sitios web de terceros desde nuestro sitio
      </dd>

      <dd>
        •Ausencia o imprecisión en obligaciones jurídicas el respectivo
        cumplimiento a la legislación en materia de comercio electrónico,
        protección de datos personales, seguridad de la información y derechos
        del consumidor
      </dd>
    </h3>

    <h3 class="text_body">
      <strong>RESPECTO A LA SEGURIDAD INFORMÁTICA</strong>
    </h3>
    <h3 class="text_body">
      En cumplimiento a las previsiones legales establecidas en la Ley Federal
      de Protección al Consumidor, el Código de Comercio, el Código Civil
      Federal, el Código Federal de Procedimientos Civiles y la Norma Mexicana
      NMXCOE-001-SCFI-2018, Comercio Electrónico - Disposiciones a las que se
      sujetarán aquellas personas que ofrezcan, comercialicen o vendan bienes,
      productos o servicios, y la Ley Federal de Protección de Datos Personales
      en Posesión de los Particulares así como su regulación secundaria, LA
      PLATAFORMA tiene incorporadas medidas de seguridad informática orientadas
      a proteger la información que comparte en sus formularios.
    </h3>

    <h3 class="text_body">
      Aun así, como consecuencia de la innovación tecnológica, no se garantiza
      que el contenido de este sitio y/o los enlaces a sitios web de terceros
      estén libres de interrupciones, errores, virus o cualquier otro componente
      nocivo.
    </h3>

    <h3 class="text_body">
      El Usuario y/o Cliente desde el momento de aceptación de estos Términos y
      Condiciones de Uso y del Servicio está de acuerdo en que Vanglar no es
      responsable directo ni indirecto de cualquier daño o perjuicio material o
      de carácter informático que puedan sufrir los dispositivos electrónicos
      y/o informáticos utilizados por el Usuario y/o Cliente y que se deriven de
      fallas o configuraciones incorrectas en nuestra plataforma, por virus,
      malware, ransomware o cualquier otro tipo de tecnología superviniente que
      llegaré a afectar el equipo del Usuario y/o Cliente como consecuencia del
      uso del sitio o por la descarga de datos, archivos, imágenes y/o
      contenidos audiovisuales. El Usuario y/o Cliente exime de cualquier
      responsabilidad a Vanglar y renuncia a exigir el pago por perjuicios
      resultantes de dificultades técnicas o fallas en LA PLATAFORMA.
    </h3>

    <h3 class="text_body"><strong>PROPIEDAD INTELECTUAL</strong></h3>
    <h3 class="text_body">
      El contenido que se provee en LA PLATAFORMA como imágenes, iconos,
      diseños, dibujos, fotografías, audios, vídeos, animaciones multimedia,
      textos, manuales, el código fuente y código objeto del sitio web y de la
      aplicación móvil están protegidas por las legislaciones nacionales e
      internacionales en materia de propiedad intelectual (propiedad industrial
      y/o derechos de autor). <strong>VANGLAR, S.A.P.I. de C.V.</strong> es
      titular de todos los derechos de propiedad industrial y/o derechos de
      autor relativos al diseño, funciones y/u operaciones que integran LA
      PLATAFORMA y sus subdominios, por lo que la licencia de uso otorgada al
      Usuario y/o Cliente no constituye una licencia para utilizar el nombre,
      contenido, diseños, signos distintivos, marcas y/o cualquier otro
      contenido. Para efecto de determinar usos autorizados, así como
      restricciones, definimos a continuación lo siguiente:
    </h3>
    <h3 class="text_body"><strong>a. Marcas</strong></h3>
    <h3 class="text_body">
      <strong>VANGLAR</strong> son signos distintivos reconocidos por el
      Instituto Mexicano de la Propiedad Industrial a favor de
      <strong>VANGLAR, S.A.P.I. de C.V.</strong>, el uso del mismo en LA
      PLATAFORMA y campañas publicitarias es de uso exclusivo de
      <strong>VANGLAR</strong>, su inclusión en LOS SERVICIOS no otorga una
      licencia de uso o cesión de derechos a favor de los Usuarios y/o Clientes.
    </h3>

    <h3 class="text_body"><strong>b. Derechos De Autor</strong></h3>
    <h3 class="text_body">
      Los contenidos en formato de textos, imágenes, audios, contenidos
      audiovisuales, código fuente y/o código objeto tienen derechos reservados
      a favor de <strong>VANGLAR, S.A.P.I. de C.V.</strong> Los contenidos
      proporcionados por el CLIENTE a <strong>VANGLAR</strong> para los fines de
      capacitación al Usuario faculta a establecer limitaciones al uso de estos.
    </h3>

    <h3 class="text_body">
      Las marcas y/o signos distintivos de las cuales VANGLAR, S.A.P.I. de C.V.
      no sea titular, pertenecen a sus correspondientes titulares y se presentan
      porque existe un contrato de publicidad entre ellos y Vanglar o porque son
      proveedores de algún servicio a través de LA PLATAFORMA.
    </h3>

    <h3 class="text_body">
      El uso de material protegido por la legislación en materia de derechos de
      autor en las publicaciones que realice el Usuario quedará sujeto al
      proceso de Aviso y Contra Aviso al cual estamos obligados en apego a las
      disposiciones establecidas en la Ley Federal del Derecho de Autor. Si un
      Usuario y/o Cliente, o alguien externo tiene conocimiento de violaciones
      en materia de derechos de autor deberá hacerlo de conocimiento de Vanglar
      vía correo electrónico al email victor.quiroz@vanglar.com
    </h3>

    <h3 class="text_body">
      Si Vanglar identifica que el CLIENTE está publicando links para realizar
      la descarga directa de obras protegidas por la Ley Federal del Derecho de
      Autor, dichos links serán eliminados y el SERVICIO al Cliente será
      suspendido por 30 días sin responsabilidad alguna para Vanglar ante
      cualquier reclamación por la falta de prestación del servicio.
    </h3>

    <h3 class="text_body">
      <strong>INTERPRETACIÓN Y JURISDICCIÓN APLICABLE</strong>
    </h3>
    <h3 class="text_body">
      Los encabezados contenidos en estos Términos del Servicio y Condiciones de
      LA PLATAFORMA tienen solamente la finalidad de que sean más claras las
      obligaciones que se contraen, por lo que de ninguna manera podrán
      considerarse como una limitación al alcance de cualquiera de los términos
      o estipulaciones de este.
    </h3>

    <h3 class="text_body">
      Para la interpretación, ejecución y cumplimiento de estos Términos y
      Condiciones, las partes interesadas se someterán a lo establecido por el
      marco legal de la Ciudad de México, México, renunciando a la jurisdicción
      del domicilio actual o futuro que pudieren tener, por lo que cualquier
      controversia que llegase a presentarse con VANGLAR, SAPI de CV será
      primordialmente resuelta entre las partes. En caso de que no se acuerde
      una solución entre las partes, el asunto en disputa deberá ser resuelto
      ante la Procuraduría Federal de Protección al Consumidor (Profeco) quien
      será la autoridad competente para conocer y resolver la controversia; solo
      en última instancia el Usuario y Vanglar, S.A.P.I de C.V acudirán a los
      Tribunales Competentes en la Ciudad de México, México.
    </h3>

    <h3 class="text_body">
      En lo que respecta a controversias que deriven del contenido que alguno de
      los Usuarios y/o Clientes exhiban, envíen o almacenen, en caso que estos
      sean constitutivos de violaciones a disposiciones legales relacionadas con
      Protección de Datos Personales, Marcas Registradas, Derechos de Autor,
      Derecho a la imagen, Ciberdelitos, entre otros, deberán ser reportados al
      correo victor.quiroz@vanglar.com para ser analizados por Vanglar quien le
      enviará una respuesta de la resolución aplicable vía correo electrónico,
      en su caso, le requeriremos mayor información para atender su denuncia.
    </h3>

    <h3 class="text_body">
      Cabe hacer mención que todas sus reclamaciones enviadas en relación con
      estos asuntos serán resueltas por Vanglar, situación que le señalaremos en
      su momento invitándole a iniciar los procedimientos legales
      correspondientes contra los terceros que estén realizando violaciones en
      las materias señaladas en esta cláusula, esto derivado de que el servicio
      que ofrecemos no tiene relación directa ni responsabilidad alguna respecto
      a las acciones que nuestros Usuarios y/o Clientes realizan.
    </h3>

    <h3 class="text_body">
      <strong>MODIFICACIONES Y/O ACTUALIZACIONES</strong>
    </h3>
    <h3 class="text_body">
      Estos Términos y Condiciones están sujetos a cambios derivados de
      modificaciones y/o actualizaciones de la legislación mexicana o bien por
      cambio a manera de operar de Vanglar. Adicional a lo anterior, le
      recomendamos que consulte constantemente esta sección en LA PLATAFORMA,
      para conocer los cambios que se realicen en la forma en que operamos, ya
      que, la aceptación de las modificaciones es condicionante para continuar
      con el uso de los servicios proveídos en este sitio.
    </h3>

    <h3 class="text_body">
      <strong
        >ATENTAMENTE, <br />
        VANGLAR, S.A.P.I. DE C.V. IDENTIFICADO COMO VANGLAR, CON DOMICILIO EN
        AVENIDA PATRIOTISMO 767 INT. 601-A; COLONIA. SAN JUAN; ALCALDÍA BENITO
        JUÁREZ; CP 03730; CIUDAD DE MÉXICO, MÉXICO Y MEDIOS DE CONTACTO
        DISPONIBLES EN LA SECCIÓN DE IDENTIFICACIÓN EN LOS PRESENTES TÉRMINOS Y
        CONDICIONES. <br />
        FECHA DE ÚLTIMA ACTUALIZACIÓN: 19/01/2021</strong
      >
    </h3>
  </b-container>
</template>

<script>
export default {};
</script>

<style>
.title {
  font-size: 40px;
}

.text_body {
  font-size: 20px;
  text-align: justify;
  margin: 30px;
}
dd {
  margin-left: 40px;
}
</style>
