import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyA7LqbYCdxuL6gxWIYESb0iVFGEGLjv-Hw',
  authDomain: 'vanglar-com.firebaseapp.com',
  databaseURL: 'https://vanglar-com.firebaseio.com',
  projectId: 'vanglar-com',
  storageBucket: 'vanglar-com.appspot.com',
  messagingSenderId: '465341893897',
  appId: '1:465341893897:web:ef70793d81eae94aa8d7d8',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { timestamp };
export default firebaseApp.firestore();
