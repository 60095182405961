<!-- eslint-disable prettier/prettier -->
<template>
  <v-app v-if="this.$store.state.postLoaded">
    <NavBar v-if="navigation"></NavBar>
    <v-main>
      <router-view />
    </v-main>
    <FooterComponent v-if="navigation"></FooterComponent>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterComponent from './components/FooterComponent.vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export default {
  name: 'App',
  metaInfo: {
    name: 'App',
    metaInfo() {
      return {
        title: 'Vanglar',
        meta: [
          {
            name: 'description',
            content:
              'Vanglar es una aplicación para estudiar diariamente diversos temas que te interesen para prepararte para una evaluación, un examen o una certificación.',
          },
          {
            property: 'og:title',
            content: '  Vanglar',
          },
          { property: 'og:site_name', content: 'Vanglar' },
          { property: 'og:type', content: 'website' },
          { name: 'robots', content: 'index,follow' },
        ],
      };
    },
  },
  data() {
    return {
      navigation: null,
    };
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      this.$store.commit('updateUser', user);
      if (user) {
        this.$store.dispatch('getCurrentUser');
      }
    });
    this.checkRoute();
    this.$store.dispatch('getPost');
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
  components: { NavBar, FooterComponent },
  methods: {
    checkRoute() {
      if (
        this.$route.name === 'login' ||
        this.$route.name === 'signup' ||
        this.$route.name === 'forgotPassword'
      ) {
        this.navigation = false;
        return;
      } else {
        this.navigation = true;
      }
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: 'BergenSans';
  src: local('BergenSans'),
    url(./fonts/BergenSans/BergenSans-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'BergenText';
  src: local('BergenText'),
    url(./fonts/BergenText/BergenText-Regular.otf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
  font-family: 'BergenSans';
}

a {
  color: inherit;
  text-decoration: none;
}

.v-main {
  background-color: white;
}

//Pagination controls disabled
.page-item:last-child,
.page-item:first-child {
  display: none;
}

.page-item .active {
  background-color: #43b9ff !important;
  border-color: #43b9ff !important;
}

.page-link {
  color: #43b9ff !important;
}

.page-item .active {
  color: white !important;
}

.btn-primary {
  margin-top: 1.5rem !important;
  border: none !important;
  background-color: #43b9ff !important;
  font-size: 1.8rem !important;
  font-weight: 800 !important;
  padding: 0.5rem 3rem !important;
}

h5 {
  font-size: 0.83em !important;
}

blockquote {
  border-left: 5px solid #cccccc;
  padding: 0 0 0 1rem !important;
}

.ql-video {
  width: 80%;
  height: 400px;
  display: block;
  margin: 0 auto;
}

.ql-editor p {
  margin: 0 !important;
}

.ql-editor {
  font-size: 20px !important;
  a {
    img {
      cursor: pointer !important;
    }
  }
}

.ql-editor {
  font-size: 20px !important;

  img {
    cursor: auto !important;
  }
}

///////////////////////////////
//////ANIMATIONS////
//////////////////////////////

@keyframes slideToRight {
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideToLeft {
  0% {
    transform: translateX(20%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideToRightOut {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(-20%);
    opacity: 0;
  }
}

@keyframes slideToLeftOut {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(20%);
    opacity: 0;
  }
}

@keyframes slideToTop {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0%);
  }

  100% {
    opacity: 1;
    transform: scale(100%);
  }
}

@keyframes bounceBubble {
  0% {
    transform: scale(0%);
    opacity: 1;
  }

  25% {
    transform: scale(120%);
    opacity: 1;
  }

  50% {
    transform: scale(90%);
    opacity: 1;
  }

  70% {
    transform: scale(105%);
    opacity: 1;
  }

  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

.slideToRight {
  animation: slideToRight 1s forwards;
}

.slideToLeft {
  animation: slideToLeft 1s forwards;
}

.slideToRightOut {
  animation: slideToRightOut 1s forwards;
}

.slideToLeftOut {
  animation: slideToLeftOut 1s forwards;
}

.slideToTop {
  animation: slideToTop 1s forwards;
}

.fadeIn {
  animation: fadeIn 0.5s forwards;
}

.bounceBubble {
  animation: bounceBubble 1.5s forwards;
}

.animation-delay-1 {
  animation-delay: 0.1s;
}

.animation-delay-2 {
  animation-delay: 0.2s;
}

.animation-delay-3 {
  animation-delay: 0.3s;
}

.animation-delay-4 {
  animation-delay: 0.4s;
}

.animation-delay-5 {
  animation-delay: 0.5s;
}

///////////////////////////////
//////RESPONSIVE FONT SIZE////
//////////////////////////////
.header1 {
  color: #1e1f72 !important;
  font-size: 6rem !important;
  font-weight: 600 !important;
  padding-bottom: 2rem !important;
  text-align: center !important;
}

.header2 {
  color: #1e1f72 !important;
  text-align: center !important;
  font-size: 3rem !important;
  font-weight: 600 !important;
  padding-bottom: 2rem !important;
  text-align: center !important;
}

.header3 {
  color: #616161 !important;
  font-size: 2.5rem !important;
  font-weight: 800 !important;
  margin-bottom: 1rem !important;
}

.text-content {
  font-size: 2.5rem !important;
  line-height: 3.2rem !important;
  text-align: center !important;
}

.list {
  text-align: left !important;
  padding-top: 2rem !important;
  font-size: 2.5rem !important;

  li {
    color: #6588e1 !important;

    span {
      color: #202121 !important;
    }
  }
}

///////////////////////////////////////
@media (max-width: 1750px) {
  .header1 {
    font-size: 5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .header3 {
    font-size: 2.1rem !important;
  }

  .text-content {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }

  .btn-primary {
    font-size: 1.6rem !important;
  }

  .list {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
  }
}

///////////////////////////////////////
@media (max-width: 1400px) {
  .header1 {
    font-size: 4.5rem !important;
  }

  .text-content {
    font-size: 1.8rem !important;
    line-height: 2.2rem !important;
  }

  .btn-primary {
    font-size: 1.4rem !important;
  }

  .list {
    padding-top: 0;
    font-size: 1.8rem !important;
    line-height: 2.2rem !important;
  }
}

///////////////////////////////////////
@media (max-width: 1100px) {
  .header1 {
    font-size: 3.5rem !important;
    padding-bottom: 0rem !important;
  }

  .header2 {
    font-size: 2rem !important;
    padding-bottom: 0rem !important;
  }

  .header3 {
    font-size: 2rem !important;
  }

  .text-content {
    font-size: 1.5rem !important;
    line-height: 1.8rem !important;
  }

  .btn-primary {
    margin-top: 0.5rem !important;
    font-size: 1.2rem !important;
  }

  .list {
    font-size: 1.3rem !important;
    line-height: 1.8rem !important;
  }
}

///////////////////////////////////////
@media (max-width: 900px) {
  .header1 {
    font-size: 3rem !important;
  }

  .text-content {
    font-size: 1.3rem !important;
    line-height: 1.6rem !important;
  }

  .btn-primary {
    font-size: 1.1rem !important;
  }

  .list {
    font-size: 1.1rem !important;
    line-height: 1.6rem !important;
  }

  .ql-video {
    height: 350px;
  }
}

///////////////////////////////////////
@media (max-width: 767px) {
  .header1 {
    padding-bottom: 1rem !important;
  }

  .header3 {
    font-size: 1.7rem !important;
  }

  .text-content {
    font-size: 1.5rem !important;
  }

  .ql-video {
    width: 100%;
    height: 300px;
  }
}

///////////////////////////////////////
@media (max-width: 604px) {
  //
}

///////////////////////////////////////
@media (max-width: 470px) {
  .header1 {
    font-size: 2.5rem !important;
  }

  .header2 {
    font-size: 1.4rem !important;
  }

  .header3 {
    font-size: 1.4rem !important;
  }

  .text-content {
    font-size: 1.1rem !important;
    line-height: 1.4rem !important;
  }

  .btn-primary {
    font-size: 0.9rem !important;
  }

  .ql-video {
    height: 200px;
  }
}
</style>
